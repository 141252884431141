import moment from 'moment';
import { config } from 'config';
import { ApiEmployee, ApiEmploymentStatus } from 'shared/types/interfaces/Api';
import { Offboarding, OffboardingEmployee } from 'shared/types/interfaces/Offboarding';
import { getFromServer } from 'apis/api';
import { employeeUrl, teamResourceUrl } from 'shared/constants/endpoints';
import { parseEmployee } from './parseEmployee';
import { FilterProps } from 'components/Timeline/Timeline';
import RandExp from 'randexp';
import { User } from 'shared/types/interfaces/User';
import { CurrentStatus } from 'shared/types/enums/CurrentStatus';
import {
  Employee,
  EmployeeShortInfo,
  EmploymentPeriod,
  EmploymentStatus,
  Name,
  Team,
  WorkingHours,
} from 'shared/types/interfaces/Employee';
import { iconGreyDark, iconGreyLight, primary1 } from 'shared/constants/colors';
import { fullFormat, isDateFormatValid, isDateObject, LOCAL_DATE_TYPES, toMoment, UTC_DATE_TYPES } from './format';
import { emailRegExp, telephoneRegExp } from 'shared/constants/shared';
import { Errors, errors } from 'shared/constants/messages';
import { ApiError } from 'shared/types/interfaces/Error';
import { today } from './date-utils';

export const getEmployeesOfTeamLead = (teamLeadId: string, employees: Employee[], from: string | Date): Employee[] => {
  const validPeriod = { from: toMoment(from, fullFormat.local), to: null };

  return employees.filter(
    (employee) =>
      employee.employmentPeriods
        .filter((employmentPeriod) => employmentPeriod.teamLead?.id === teamLeadId)
        .filter(
          (employmentPeriod) =>
            employmentPeriod.to === null ||
            validPeriod.from.isBefore(employmentPeriod.to) ||
            validPeriod.from.isSame(employmentPeriod.to)
        ).length > 0
  );
};

const dateFormat = 'YYYY-MM-DD';

export const calculateSum = (workingHours: WorkingHours) =>
  workingHours.monday + workingHours.tuesday + workingHours.wednesday + workingHours.thursday + workingHours.friday;

export const getWorkingDaysPerWeek = (workingHours: WorkingHours) => {
  const dayValues = [
    workingHours.monday,
    workingHours.tuesday,
    workingHours.wednesday,
    workingHours.thursday,
    workingHours.friday,
  ];
  return dayValues.reduce((sum, value) => sum + (value > 0 ? 1 : 0), 0);
};

export const calculateCoefficient = (sum: number) => sum / 40.0;

export const makeId = () => {
  let id = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 8; i++) {
    id += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return id;
};

export const makeEmploymentPeriodId = () =>
  new RandExp(/^[0-9][a-z]{8}-[0-9][a-z]{4}-[0-9][a-z]{4}-[0-9][a-z]{4}-[0-9][a-z]{12}$/).gen();

export const buildUrlParam = (params: { [key: string]: string | undefined }) => {
  if (!params) {
    return undefined;
  }
  const result = Object.keys(params).reduce((urlParam: string, param: string) => {
    if (!params[param]) {
      return urlParam;
    } else {
      if (urlParam !== '') {
        urlParam = urlParam + '&';
      }
      return urlParam + param + '=' + params[param];
    }
  }, '');
  if (result === '') {
    return undefined;
  }
  return result;
};

export const formatDateToString = (date: Date | string | null) =>
  date == null ? '-' : moment(date).format(fullFormat.local);

export const isStudent = (employmentStatusId: number) =>
  isThesisStudent(employmentStatusId) ||
  isWorkingStudent(employmentStatusId) ||
  isTemporaryHelpStudent(employmentStatusId) ||
  isIntern(employmentStatusId);

export const isThesisStudent = (employmentStatusId: number) =>
  [...config.employmentStatusIds.thesisStudent].indexOf(employmentStatusId) !== -1;

export const isLeadingEmployee = (employmentStatusId: number) =>
  [...config.employmentStatusIds.leadingEmployee].indexOf(employmentStatusId) !== -1;

export const isOperativeEmployee = (employmentStatusId: number) =>
  [...config.employmentStatusIds.operativeEmployee].indexOf(employmentStatusId) !== -1;

export const isTrainee = (employmentStatusId: number) =>
  [...config.employmentStatusIds.trainee].indexOf(employmentStatusId) !== -1;

export const isWorkingStudent = (employmentStatusId: number) =>
  [...config.employmentStatusIds.workingStudent].indexOf(employmentStatusId) !== -1;

export const isTemporaryHelpStudent = (employmentStatusId: number) =>
  [...config.employmentStatusIds.temporaryHelpStudent].indexOf(employmentStatusId) !== -1;

export const isIntern = (employmentStatusId: number) =>
  [...config.employmentStatusIds.intern].indexOf(employmentStatusId) !== -1;

export const isTemporaryHelp = (employmentStatusId: number) =>
  [...config.employmentStatusIds.temporaryHelp].indexOf(employmentStatusId) !== -1;

export const isApprentices = (employmentStatusId: number) =>
  [
    ...config.employmentStatusIds.trainee.filter(
      (id) =>
        [
          ...config.employmentStatusIds.workingStudent,
          ...config.employmentStatusIds.thesisStudent,
          ...config.employmentStatusIds.intern,
        ].indexOf(id) === -1
    ),
  ].indexOf(employmentStatusId) !== -1;

export const hasPerformanceReview = (employmentStatusId: number) =>
  isOperativeEmployee(employmentStatusId) || isLeadingEmployee(employmentStatusId) || isApprentices(employmentStatusId);

export const hasJobTitle = (employmentStatusId: number) =>
  isOperativeEmployee(employmentStatusId) || isLeadingEmployee(employmentStatusId);

export const formatName = (name: Name | undefined, lastNameFirst?: boolean) =>
  lastNameFirst
    ? `${name ? name.lastName : ''}, ${name ? name.firstName : ''}`
    : `${name ? name.firstName : ''} ${name ? name.lastName : ''}`;

export const formatSearchName = (employee: Employee | EmployeeShortInfo | OffboardingEmployee) =>
  `${employee.name ? employee.name.lastName : ''} ${employee.name ? employee.name.firstName : ''} ${employee.username}`;

/**
 * Translate ue, oe and ae to u, o and a
 * https://en.wikipedia.org/wiki/Orthographic_ligature
 *
 * @param stringToFormat
 */
export const deburrLigature = (stringToFormat: string) =>
  stringToFormat.replace(/ue/, 'u').replace(/oe/, 'o').replace(/ae/, 'a');

export const formatEmploymentStatus = (status: EmploymentStatus | ApiEmploymentStatus) =>
  status.name + (status.detail ? ' - ' + status.detail : '');

export const getEmploymentPeriodById = (id: string, employmentPeriods: EmploymentPeriod[]): EmploymentPeriod | null => {
  for (const e of employmentPeriods) {
    if (e.id === id) {
      return e;
    }
  }
  return null;
};

export const getEmploymentPeriodByDate = (
  employmentPeriods: EmploymentPeriod[],
  fromPara: string | Date
): EmploymentPeriod | null => {
  const from = !isDateFormatValid(fromPara, fullFormat.local) ? toMoment(fromPara).format(fullFormat.local) : fromPara;

  for (const e of employmentPeriods) {
    const periodFrom = toMoment(e.from).format(fullFormat.local);
    const periodTo = e.to === null ? null : toMoment(e.to).format(fullFormat.local);

    // From matching
    if (periodFrom === from) {
      return e;
    }

    // To matching
    if (periodTo === from) {
      return e;
    }

    const fromMoment = toMoment(from, fullFormat.local);
    const periodFromMoment = toMoment(periodFrom, fullFormat.local);
    if (
      (fromMoment.isAfter(periodFromMoment) && periodTo === null) ||
      fromMoment.isBetween(periodFromMoment, toMoment(periodTo, fullFormat.local))
    ) {
      return e;
    }
  }

  return null;
};

export const getEmploymentPeriodByStartDate = (
  employmentPeriods: EmploymentPeriod[],
  fromPara: string
): EmploymentPeriod | undefined => {
  const from = typesToMoment(fromPara);

  return employmentPeriods.find((period) => {
    const periodFrom = typesToMoment(period.from);
    const periodTo = period.to === null ? null : typesToMoment(period.to);

    return periodTo !== null ? from.isBetween(periodFrom, periodTo) : from.isAfter(periodFrom);
  });
};

/**
 *
 * @param employmentPeriods: current periods of employee
 * @param fromPara: beginning of selected date (expected in local format)
 * @param toPara: end of selected date (expected in local format)
 * Returns the exact Period if the Dates matches
 * Returns the Period outside the Dates
 */
export const getEmploymentPeriodByDates = (
  employmentPeriods: EmploymentPeriod[],
  fromPara: string | Date,
  toPara: string | Date | null
): EmploymentPeriod | null => {
  const from = typesToLocalDate(fromPara);

  // If toPara is in unexpected format -> format to local
  const to = toPara ? typesToLocalDate(toPara) : toPara;

  // // Convert the different input possabilities to Moment object
  // const from = typesToMoment(fromPara);

  // // Convert the different input possabilities to Moment object
  // const to = toPara ? typesToMoment(toPara) : toPara;

  return employmentPeriods.find((period) => isRelatedPeriod(period, from, to)) ?? null;
};

const isRelatedPeriod = (period: EmploymentPeriod, from: string, to: string | null) => {
  const periodFrom = toMoment(period.from).format(fullFormat.local);
  const periodTo = period.to === null ? null : toMoment(period.to).format(fullFormat.local);

  // Dates matching
  if (periodFrom === from && periodTo === to) {
    return true;
  }

  // From matching
  if (periodFrom === from) {
    return true;
  }

  // To matching
  if (periodTo === to) {
    return true;
  }

  // From and to not matching
  if (
    (to !== null &&
      periodTo !== null &&
      toMoment(to, fullFormat.local).isBetween(
        toMoment(periodFrom, fullFormat.local),
        toMoment(periodTo, fullFormat.local)
      ) &&
      toMoment(from, fullFormat.local).isBetween(
        toMoment(periodFrom, fullFormat.local),
        toMoment(periodTo, fullFormat.local)
      )) ||
    (periodTo === null &&
      to !== null &&
      toMoment(from, fullFormat.local).isAfter(toMoment(periodFrom, fullFormat.local)))
  ) {
    return true;
  }
  return false;
};

export const typesToLocalDate = (date: string | Date) =>
  isDateObject(date)
    ? toMoment(date).format(fullFormat.local)
    : !isDateFormatValid(date, fullFormat.local)
    ? toMoment(date).format(fullFormat.local)
    : (date as string);

export const typesToMoment = (date: string | Date) =>
  isDateObject(date)
    ? toMoment(date)
    : !isDateFormatValid(date, fullFormat.local)
    ? toMoment(date, fullFormat.utc)
    : toMoment(date, fullFormat.local);

export const getClosestEmploymentPeriod = (
  employmentPeriods: EmploymentPeriod[],
  date: string | Date
): EmploymentPeriod => {
  const employmentPeriod = getEmploymentPeriodByDate(employmentPeriods, date);
  if (employmentPeriod) return employmentPeriod;

  // search for nearest future employment period
  let closestPeriod: EmploymentPeriod | null = null;
  for (const employmentPeriod of employmentPeriods) {
    if (moment(employmentPeriod.from).isAfter(date)) {
      closestPeriod = employmentPeriod;
      break;
    }
  }

  for (const employmentPeriod of employmentPeriods) {
    if (employmentPeriod.to != null && moment(employmentPeriod.to).isBefore(date)) {
      if (closestPeriod == null || moment(employmentPeriod.to).isAfter(moment(closestPeriod.to, 'YYYY-MM-DD'))) {
        closestPeriod = employmentPeriod;
      }
    }
  }

  if (closestPeriod == null) {
    closestPeriod = employmentPeriods[employmentPeriods.length - 1];
  }

  return closestPeriod;
};

export const getCurrentAndFutureEmploymentPeriods = (employmentPeriods: EmploymentPeriod[]): EmploymentPeriod[] => {
  const periods: EmploymentPeriod[] = [];
  for (const e of employmentPeriods) {
    if (e.to === null) {
      if (
        moment(today().format(dateFormat)).isAfter(moment(e.from).format(dateFormat)) ||
        moment(today().format(dateFormat)).isBefore(moment(e.from).format(dateFormat))
      ) {
        periods.push(e);
      }
    } else {
      if (
        moment(today().format(dateFormat)).isBetween(
          moment(e.from).format(dateFormat),
          moment(e.to).format(dateFormat),
          undefined,
          '[]'
        ) ||
        moment(today().format(dateFormat)).isBefore(moment(e.from).format(dateFormat))
      ) {
        periods.push(e);
      }
    }
  }
  return periods;
};

// run through array of statusIds: nur die Stati
// reverse: alle bis auf die Stati

export const filterEmploymentPeriods = (
  employmentPeriods: EmploymentPeriod[],
  onlyCurrentAndFuturePeriods: boolean,
  filter: FilterProps | undefined
): EmploymentPeriod[] => {
  let periods;

  if (filter && filter.noPeriods) {
    return [];
  }

  if (onlyCurrentAndFuturePeriods) {
    periods = getCurrentAndFutureEmploymentPeriods(employmentPeriods);
  } else {
    periods = employmentPeriods;
  }

  if (filter) {
    if (filter.status) {
      const filterdPeriods = [];
      for (const p of periods) {
        for (const i of filter.status) {
          if (p.employmentStatus!.shortName === i) {
            filterdPeriods.push(p);
          }
        }
      }
      periods = filterdPeriods;
    }
    if (filter.onlyFuturePeriods) {
      // TODO: filter only future periods
      const filterdPeriods = [];
      for (const p of periods) {
        const from = moment(p.from).format('YYYY-MM-DD');
        const timestamp = moment().format('YYYY-MM-DD');
        if (moment(from).isAfter(timestamp)) {
          filterdPeriods.push(p);
        }
      }
      periods = filterdPeriods;
    }
  }

  return periods;
};

export const getEmploymentPeriodsByStatus = (
  employmentPeriods: EmploymentPeriod[],
  statusId: number
): EmploymentPeriod[] => {
  const periods: EmploymentPeriod[] = [];
  for (const e of employmentPeriods) {
    if (e.employmentStatus!.id === statusId) {
      periods.push(e);
    }
  }

  // TODO: wenn nur noch aktuelle und Zukünftige bearbeitbar sind: call 'getActiveAndFutureEmploymentPeriods'
  return periods;
};

export const getEmployee = async (employeeId: string) => getFromServer<ApiEmployee>(`${employeeUrl}/${employeeId}`);

export const getEmployeeName = async (employeeId: string) => {
  const employee = {
    ...parseEmployee(await getEmployee(employeeId)),
  };

  return employee.name.firstName + ' ' + employee.name.lastName;
};

export const isTeamLeadUser = async (user: User, employee?: Employee) => {
  if (!employee) {
    try {
      employee = parseEmployee(await getEmployee(user.uuid));
    } catch (error) {
      console.log(error);
    }
  }
  return !!(employee && employee?.closestPeriod.employmentStatus?.isLeading);
};

export const isTeamLeadOf = (user: User, employee: Employee) =>
  !!(employee && employee?.closestPeriod?.teamLead?.id === user.uuid);

export const sameTeam = (e1: Employee, e2: Employee): boolean => {
  const team1 = e1.closestPeriod?.team;
  const team2 = e2.closestPeriod?.team;
  if (!team1 || !team2) {
    return false;
  }

  const inovexTeamId = '090b801b-5c4e-4de0-83a6-2e1c89ab8e76';
  if (
    team1.id === team2.id ||
    (team1.parentId && team1.parentId !== inovexTeamId && team1.parentId === team2.parentId) ||
    (team1.id && team1.id === team2.parentId) ||
    (team1.parentId && team1.parentId === team2.id)
  ) {
    return true;
  }
  return false;
};

export const isUserOnlySupervisor = async (user: User) => {
  let isTeamLead = false;

  try {
    const employee = parseEmployee(await getEmployee(user.uuid));
    isTeamLead = await isTeamLeadUser(user, employee);
  } catch (error) {
    console.log(error);
  }

  return !isTeamLead && !isFinanceUser(user) && !isPncUser(user) && !isAdminUser(user) && !isPayrollUser(user);
};

export const getTeamName = async (teamId: string) => {
  let teamName = '';
  await getFromServer<Team>(`${teamResourceUrl}/${teamId}`).then((response: Team) => {
    teamName = response.name;
  });

  return teamName;
};

export const sanitizePhoneNumber = (phoneNumber: string): string => phoneNumber.trim().replace(/ |-/g, '');

export const getProbationByStatus = (employmentStatus: number): number => {
  let probation = 0;
  const { employmentStatusProbationMap } = config;

  for (const key in employmentStatusProbationMap) {
    if (employmentStatusProbationMap[key].includes(employmentStatus)) {
      probation = parseInt(key, 10);
    }
  }

  return probation;
};

export const calculateProbationEnd = (
  startDate: string | Date,
  probation: number,
  format?: LOCAL_DATE_TYPES | UTC_DATE_TYPES
): moment.Moment => {
  const convertedDate = format ? toMoment(startDate, format) : toMoment(startDate, fullFormat.utc);
  const probationEndDate = convertedDate.add(probation, 'months');
  if (probation > 0) {
    probationEndDate.subtract(1, 'days');
  }

  return probationEndDate;
};

interface StatusIconProps {
  inoIcon: string;
  color: string;
}

export const getEmployeeStatusIcon = (employee: Employee): StatusIconProps => {
  const iconProps: StatusIconProps = { inoIcon: 'status_aktuelle', color: iconGreyDark };

  if (employee.activeOffboarding) {
    iconProps.inoIcon = 'status_offboarding_laufend';
    return iconProps;
  }

  if (employee.absence) {
    iconProps.inoIcon = 'status_abwesend';
    return iconProps;
  }

  if (employee.closestPeriod.from) {
    // employment period end is in the past
    if (employee.closestPeriod.to && moment(employee.closestPeriod.to).isBefore(today().format('YYYY-MM-DD'))) {
      iconProps.inoIcon = 'status_vergangene';
      iconProps.color = iconGreyLight;
      // employment period start is in the future
    } else if (moment(employee.closestPeriod.from).isAfter(today().format('YYYY-MM-DD'))) {
      iconProps.inoIcon = 'status_zukuenftige';
      iconProps.color = primary1;
    }
  }
  return iconProps;
};

export const isValidEmployeeSelectValue = (value: string, employees: {}): boolean =>
  Object.keys(employees).includes(value);

// return all existing offboardings or offboardings for employee with id: employeeId
export const getOffboardings = async (employeeId?: string) => {
  let offboardings: Offboarding[] = [];

  if (employeeId) {
    await getFromServer<Offboarding[]>('/offboarding?employeeId=' + employeeId).then((response: Offboarding[]) => {
      if (response) {
        offboardings = response;
      }
    });
  } else {
    await getFromServer<Offboarding[]>('/offboarding').then((response: Offboarding[]) => {
      if (response) {
        offboardings = response;
      }
    });
  }

  return offboardings;
};
export const getActiveOffboarding = (employeeId: string, offboardings?: Offboarding[]): Offboarding | null => {
  if (offboardings && offboardings.length > 0) {
    for (const offboarding in offboardings) {
      if (
        offboardings &&
        offboardings[offboarding].employeeId === employeeId &&
        moment(offboardings[offboarding].offboardingDate).isSameOrAfter(today().format('YYYY-MM-DD'))
      ) {
        return offboardings[offboarding];
      }
    }
  }
  return null;
};

export const getCurrentStatus = (employee: Employee): CurrentStatus => {
  let status = CurrentStatus.CURRENT;

  if (employee.absence && employee.activeOffboarding) {
    return CurrentStatus.ABSENT_WITH_OFFBOARDING;
  } else if (employee.absence) {
    return CurrentStatus.ABSENT;
  } else if (employee.activeOffboarding) {
    return CurrentStatus.OFFBOARDING_STARTED;
  }

  for (const employmentPeriod of employee.employmentPeriods) {
    if (employmentPeriod.to && moment(employmentPeriod.to).isBefore(today().format('YYYY-MM-DD'))) {
      status = CurrentStatus.PAST;
    } else if (
      moment(employmentPeriod.from).isSameOrBefore(moment.now()) &&
      (!employmentPeriod.to || moment(employmentPeriod.to).isSameOrAfter(today().format('YYYY-MM-DD')))
    ) {
      status = CurrentStatus.CURRENT;
      break;
    } else {
      status = CurrentStatus.FUTURE;
    }
  }
  return status;
};

export const convertDates = <K extends object>(obj: K): K => {
  const convert = (k: keyof K, v: any) => {
    if (obj[k] instanceof Date) {
      const momentDate = moment(v as Date);
      if (momentDate.isValid()) {
        (obj as any)[k] = momentDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS');
      }
    } else if (!!obj[k] && typeof obj[k] === 'object') {
      // recursively call convertDates for nested object
      convertDates(obj[k] as K);
    }
  };

  Object.entries(obj).forEach(([k, v]) => {
    convert(k as keyof K, v);
  });

  return obj;
};

export const getMonthNameByValue = (month: number): string => {
  const monthsName = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  return monthsName[month];
};

export const getAnnualHolidays = (workingHours: WorkingHours): number =>
  Math.round(getWorkingDaysPerWeek(workingHours) * 0.2 * 2.5 * 12);

export const getRemainingHolidays = (
  workingHours: WorkingHours,
  startDate: Date,
  endDate?: Date
): number | undefined => {
  if (startDate.getUTCDate() !== 1) {
    return undefined;
  }

  if (endDate) {
    const lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    if (moment(endDate).format('L') !== moment(lastDay).format('L')) {
      return undefined;
    }
  }

  const workDaysPerWeek = getWorkingDaysPerWeek(workingHours);

  let contractLength = 0;

  if (endDate === undefined || startDate.getFullYear() !== endDate.getFullYear()) {
    contractLength = 12 - startDate.getMonth();
  } else if (startDate.getFullYear() === endDate.getFullYear()) {
    contractLength = endDate.getMonth() + 1 - startDate.getMonth();
  }

  let result = workDaysPerWeek * 0.2 * 2.5 * contractLength;

  if (Math.floor(result) < Math.round(result)) {
    result = Math.floor(result) + 0.5;
  } else {
    result = Math.floor(result);
  }
  return result;
};

export const transformPhoneNumber = (phoneNumber: string): string => {
  const filteredNumber: string = phoneNumber.replace(/[^+0-9]/g, '');
  if (filteredNumber.startsWith('+')) {
    return filteredNumber;
  }
  if (filteredNumber.startsWith('00')) {
    return '+' + (filteredNumber.length > 2 ? filteredNumber.substring(2) : '');
  }
  if (filteredNumber.startsWith('0') && filteredNumber.length > 1) {
    return '+49' + filteredNumber.substring(1);
  }
  if (filteredNumber.length > 0 && !filteredNumber.startsWith('0')) {
    return '+' + filteredNumber;
  }
  return filteredNumber;
};

export const getUserRole = (user: User) => {
  if (isAdminUser(user)) {
    return 'Admin';
  } else if (isPncUser(user)) {
    return 'P&C';
  } else if (isPayrollUser(user)) {
    return 'Gehalt';
  } else if (isFinanceUser(user)) {
    return 'Finance';
  } else {
    return 'User';
  }
};

export const isAdminUser = (user: User | null): boolean =>
  !!user && !!user.authorities && user.authorities.includes('ROLE_ADMIN');

export const isPncUser = (user: User | null): boolean =>
  !!user && !!user.authorities && user.authorities.includes('ROLE_PNC');

export const isPayrollUser = (user: User | null): boolean =>
  !!user && !!user.authorities && user.authorities.includes('ROLE_PAYROLL');

export const isFinanceUser = (user: User | null): boolean =>
  !!user && !!user.authorities && user.authorities.includes('ROLE_FINANCE');

export const isOnlyFinanceUser = (user: User | null): boolean =>
  isFinanceUser(user) && !isPncUser(user) && !isPayrollUser(user) && !isAdminUser(user);

export const isOnlyFinanceOrPayrollUser = (user: User | null): boolean =>
  (isFinanceUser(user) || isPayrollUser(user)) && !isPncUser(user) && !isAdminUser(user);

export const isValidPhoneNumber = (phoneNumber: string | null): boolean => {
  if (phoneNumber === null) {
    return false;
  }
  return new RegExp(telephoneRegExp).test(phoneNumber);
};

export const isValidEmailAddress = (emailAddress: string | null): boolean => {
  if (emailAddress === null) {
    return false;
  }
  return new RegExp(emailRegExp).test(emailAddress);
};

export const isValidFte = (fte: number, employmentStatusId: number): boolean => {
  if (isThesisStudent(employmentStatusId) || isTemporaryHelpStudent(employmentStatusId)) {
    return fte === 0;
  } else if (isWorkingStudent(employmentStatusId) || isIntern(employmentStatusId)) {
    return fte >= 0.0 && fte <= 1.0;
  } else {
    return fte > 0.0 && fte <= 1.0;
  }
};

export const getErrorMessage = (errorCode: string): string => {
  const keys = errorCode.split('.');
  if (keys.length === 0) {
    return '';
  }
  let messageObj = errors[keys[0]];

  for (let i = 1; i < keys.length; i++) {
    if (messageObj) {
      messageObj = (messageObj as Errors)[keys[i]];
    }
  }
  if (!messageObj) {
    messageObj = errors.generic;
  }
  return String(messageObj);
};

export const getApiErrorMessages = (apiError: ApiError): string[] => {
  const errorMessages: string[] = [];
  if (!apiError.data) {
    errorMessages.push(getErrorMessage(apiError.message));
    return errorMessages;
  }

  if (apiError.data && apiError.data.errors) {
    apiError.data.errors.forEach((errorCode) => errorMessages.push(getErrorMessage(errorCode)));
  }

  if (apiError.data && apiError.data.fieldErrors) {
    Object.keys(apiError.data.fieldErrors)
      .flatMap((fieldErrorKey) => apiError.data.fieldErrors[fieldErrorKey])
      .forEach((errorCode: string) => errorMessages.push(getErrorMessage(errorCode)));
  }

  return errorMessages;
};
